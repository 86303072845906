import Section1 from "./Offer/Section1";
import Section2 from "./Offer/Section2";
import Section3 from "./Offer/Section3";
import Section4 from "./Offer/Section4";
import '../../styles/offer.css';

export default function Offer(){
    return(
        <>
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
        </>
    )
}